@import '@mca/styles/primeng/primeng';
@import '@mca/styles-main/bootstrap/variables';

// app primeng customizations goes here

.p-checkbox .p-checkbox-box.p-highlight {
  background: $purple-gray;
  border-radius: $input-border-radius;
  border-color: $purple-gray;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: $purple-gray;
  border-color: $purple-gray;
}

.p-dropdown.p-disabled {
  background-color: #e7ebef;
}

p-calendar,
.p-calendar {
  width: 100%;
}

.p-togglebutton.p-button {
  border-radius: 6px;
  font-size: 12px;
  &.p-highlight {
    background-color: $light-purple;
    &:hover {
      background-color: $light-purple-hover;
    }
  }
}

.p-checkbox-label {
  color: $purple-gray;
}
p-dropdown .p-dropdown,
input.p-inputtext,
.p-component input.ui-state-default {
  border: 1px solid #ced4da;
  border-radius: $input-border-radius;
}
.p-datatable .p-datatable-tbody {
  .mca-success {
    background: #bfe6d0;
    &:hover {
      background: #c4eed6;
    }
  }
  .mca-complete {
    background: #fdf4b3;
    &:hover {
      background: #fff8c6;
    }
  }
}
