@import 'variables';
@import 'font-awesome/scss/variables';
@import 'font-awesome/scss/mixins';

@import '@mca/styles/amplify';

@import 'bootstrap';
@import 'primeng';
@import 'amplify-customization';
@import '@mca/styles/components.scss';
@import '@mca/styles/utilities.scss';

.content-main {
  padding: 1rem;
  background: #f2f2f5;
}

.tran-processed {
  background-color: $success !important;
  color: $white !important;
}

.page-actions {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.mcalist-grid .grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 6px;
  row-gap: 6px;
  align-items: stretch;

  &::before {
    display: none;
  }
}

app-lender-offers {
  .p-datatable .p-datatable-thead > tr > th {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
  }
}

.merchant-form-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-left: 20px;
  &_label {
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #5a5b6b;
    width: 35%;
    margin-right: 8px;
  }
  &_input {
    max-width: 65%;
  }
}

.side-notes {
  .p-dataview .p-dataview-content {
    background: unset;
  }
  // For making Notes side bar hidden by default and extended on hover
  //@extend .panel--hovered;
}
