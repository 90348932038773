:root {
  --amplify-colors-background-primary: var(--amplify-colors-neutral-90);
  --amplify-colors-background-secondary: var(--amplify-colors-neutral-100);
  --amplify-colors-brand-primary-10: var(--amplify-colors-teal-100);
  --amplify-colors-brand-primary-80: var(--amplify-colors-teal-40);
  --amplify-colors-brand-primary-90: var(--amplify-colors-teal-20);
  --amplify-colors-brand-primary-100: var(--amplify-colors-teal-10);
  --amplify-components-button-primary-background-color: #0493e1;
  --amplify-components-heading-color: white;
  --amplify-components-button-primary-hover-background-color: #6cc9f6;
}

.amplify-input {
  background-color: #e8f0fe;
}

[data-amplify-authenticator] [data-amplify-router] {
  border-radius: 20px;
}
