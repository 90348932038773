// colors

$light-orange: #d79467;
$light-orange-hover: #cb8353;

$light-purple: #7a89fe;
$light-purple-hover: #6471d9;

$purple-gray: #747898;
$purple-gray-hover: #6875d9;

// buttons
$btn-border-radius: 8px;
$input-border-radius: 6px;
